export var NodeType;
(function (NodeType) {
    NodeType[NodeType["Org"] = 0] = "Org";
    NodeType[NodeType["Dept"] = 1] = "Dept";
    NodeType[NodeType["User"] = 2] = "User";
})(NodeType || (NodeType = {}));
export var OperateType;
(function (OperateType) {
    OperateType[OperateType["Add"] = 0] = "Add";
    OperateType[OperateType["Edit"] = 1] = "Edit";
    OperateType[OperateType["Del"] = 2] = "Del";
})(OperateType || (OperateType = {}));
export var SourceType;
(function (SourceType) {
    SourceType["SelfMaintain"] = "SELF_MAINTENANCE";
    SourceType["DingtalkMaintain"] = "DINGTALK";
    SourceType["WechatMaintain"] = "WECHAT";
})(SourceType || (SourceType = {}));
export const selfMaintain = {
    label: '云枢自维护',
    value: SourceType.SelfMaintain,
    alias: 'selfMaintain',
};
export const dingtalkMaintain = {
    label: '钉钉集成',
    value: SourceType.DingtalkMaintain,
    alias: 'dingtalkMaintain',
};
export const wechatMaintain = {
    label: '企业微信集成',
    value: SourceType.WechatMaintain,
    alias: 'wechatMaintain',
};
export const SourceTypeValueToLabelMap = {
    [selfMaintain.value]: selfMaintain.label,
    [dingtalkMaintain.value]: dingtalkMaintain.label,
    [wechatMaintain.value]: wechatMaintain.label,
};
export var UserStatus;
(function (UserStatus) {
    UserStatus["Enable"] = "ENABLE";
    UserStatus["Disable"] = "DISABLE";
})(UserStatus || (UserStatus = {}));
export var UserPageType;
(function (UserPageType) {
    UserPageType[UserPageType["UserList"] = 0] = "UserList";
    UserPageType[UserPageType["UserDetail"] = 1] = "UserDetail";
    UserPageType[UserPageType["UserEdit"] = 2] = "UserEdit";
})(UserPageType || (UserPageType = {}));
export var UnitType;
(function (UnitType) {
    UnitType[UnitType["Dept"] = 1] = "Dept";
    UnitType[UnitType["Role"] = 2] = "Role";
    UnitType[UnitType["User"] = 3] = "User";
})(UnitType || (UnitType = {}));
export const sexs = {
    0: {
        value: 0,
        label: '男',
    },
    1: {
        value: 1,
        label: '女',
    },
};
export var Strategy;
(function (Strategy) {
    Strategy["Simple"] = "simple";
    Strategy["SelfMaintain"] = "selfmaintain";
    Strategy["ExcludeChild"] = "exclude_child";
    Strategy["OrgManageCode"] = "org-manage";
    Strategy["UserManageCode"] = "org-user-manage";
})(Strategy || (Strategy = {}));
