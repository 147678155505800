import { __decorate } from "tslib";
import { Component, Vue, Ref, Watch, } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import { SourceTypeValueToLabelMap } from '../../../typings/organization/org-manage';
import { namespace } from 'vuex-class';
const Organization = namespace('Organization');
let OrgConfig = class OrgConfig extends Vue {
    constructor() {
        super(...arguments);
        this.isSubmitLoading = false;
        this.showAddOrg = false;
        this.keyword = '';
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'name',
                key: 'name',
                ellipsis: true,
                slots: { title: 'nameTitle', name: '组织名称' },
                scopedSlots: { customRender: 'name' },
            },
            {
                dataIndex: 'sourceType',
                key: 'sourceType',
                slots: { title: 'sourceTypeTitle', name: '维护方式' },
            },
            {
                dataIndex: 'parentDepartmentName',
                key: 'parentDepartmentName',
                slots: { title: 'parentDepartmentNameTitle', name: '上级' },
            },
            {
                dataIndex: 'orgStop',
                key: 'orgStop',
                slots: { title: 'orgStopTitle', name: '状态' },
                scopedSlots: { customRender: 'orgStop' },
            },
            {
                width: 100,
                dataIndex: 'action',
                key: 'action',
                align: 'center',
                slots: { title: 'actionTitle', name: '操作' },
                scopedSlots: { customRender: 'action' },
            },
        ];
    }
    onKeywordChange() {
        this.keyword = this.keyword.replace(/^\s*|\s*$/g, '');
    }
    async getTableList(params = {}) {
        const { keyword } = this;
        const res = await OrgManageService.searchOrgConfigList({
            ...params,
            keyword,
        });
        const data = res.data || [];
        data.forEach((item) => {
            item.sourceType = SourceTypeValueToLabelMap[item.sourceType];
            item.parentDepartmentName = item.parentDepartmentName || '--';
        });
        return res;
    }
    getOrgList() {
        return this.CTable.tableData || [];
    }
    save() {
        this.isSubmitLoading = true;
        this.orgConfigForm.submit().then((res) => {
            if (res.success) {
                return this.checkOrg();
            }
        }).finally(() => {
            this.isSubmitLoading = false;
        });
    }
    addOrg() {
        this.showAddOrg = true;
    }
    goPage(item) {
        const { id } = item;
        this.$router.push({
            name: 'org-manage-detail',
            params: {
                id,
            },
        });
    }
    /**
     * 搜索
     */
    search(keyword) {
        this.keyword = keyword;
        this.CTable.pagination.current = 1;
        this.CTable.pagination.pageSize = 20;
        this.CTable.getTableList();
    }
    reload() {
        this.search(this.keyword);
    }
};
__decorate([
    Organization.State('orgCount')
], OrgConfig.prototype, "orgCount", void 0);
__decorate([
    Organization.Action('checkOrg')
], OrgConfig.prototype, "checkOrg", void 0);
__decorate([
    Ref()
], OrgConfig.prototype, "CTable", void 0);
__decorate([
    Ref()
], OrgConfig.prototype, "orgConfigForm", void 0);
__decorate([
    Watch('keyword')
], OrgConfig.prototype, "onKeywordChange", null);
OrgConfig = __decorate([
    Component({
        name: 'OrgConfig',
        components: {
            OrgConfigForm: () => import('@/components/sys-manage/org-manage/org-config-form.vue'),
            CTable: () => import('@/components/shared/CTable/index.vue'),
            addOrg: () => import('@/components/sys-manage/org-manage/add-org.vue'),
        },
    })
], OrgConfig);
export default OrgConfig;
